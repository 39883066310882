<template>
  <div class="add-cred-form">
    <div class="select-inset-label">
      <label>Connection Name</label>
      <n-input v-model:value="name" />
    </div>

    <div class="select-inset-label">
      <label>Credentials</label>
      <n-select v-model:value="credentials" :options="credentialIdOptions" />
    </div>

    <div class="select-inset-label">
      <label>Platform</label>
      <n-select v-model:value="type" :options="typeOptions" />
    </div>

    <div v-if="serviceFields[type]">
      <div class="select-inset-label" v-for="fieldKey in Object.keys(serviceFields[type])" :key="fieldKey">
        <label>{{ serviceFields[type][fieldKey].label }}</label>
        <n-input 
          v-model:value="serviceFields[type][fieldKey].value" 
          v-if="serviceFields[type][fieldKey].type === 'n-input'" 
        />
        <n-select 
          v-model:value="serviceFields[type][fieldKey].value" 
          v-if="serviceFields[type][fieldKey].type === 'n-select'" 
          :options="serviceFields[type][fieldKey].options" 
        />
        <n-dynamic-tags 
          v-model:value="serviceFields[type][fieldKey].value" 
          v-if="serviceFields[type][fieldKey].type === 'n-dynamic-tags'" 
        />
      </div>
    </div>    

    <div class="site-error" v-if="error && !loading">{{ error }}</div>

    <div class="buttons">
      <button class="button white-btn" @click="this.closeClick" v-if="this.closeClick">
        Close
      </button>
      <button class="button blue-btn" @click="editContainer.container ? update() : add()">
        {{ editContainer.container ? "Update" : "Add" }}
      </button>
    </div>

    <div class="site-loading" v-if="!error && loading">
      <LoadingSpinner /> Loading...
    </div>
  </div>
</template>

<script>
import CredentialService from "@/services/CredentialService";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { NSelect, NInput, NDynamicTags } from "naive-ui";
import MetadataService from "@/services/MetadataService";

export default {
  name: "AddForm",
  components: { NSelect, NInput, NDynamicTags, LoadingSpinner },
  props: [
    "closeClick",
    "editContainer"
  ],
  data() {
    return {
      loading: false,
      error: null,
      name: "",
      type: "",
      credentials: "",
      typeOptions: [
        {
          'label': 'DV360',
          'value': 'dv360'
        },
        {
          'label': 'SA360',
          'value': 'sa360'
        },
        {
          'label': 'CM360',
          'value': 'cm360'
        },
        {
          'label': 'Google Ads',
          'value': 'googleAds'
        },
        {
          'label': 'GA4',
          'value': 'ga4'
        },
        {
          'label': 'GTM',
          'value': 'gtm'
        },
        {
          'label': 'Meta',
          'value': 'meta'
        }
      ],
      credentialIdOptions: [],
      serviceFields: {
        gtm: {
          accountId: {
            label: "Account ID",
            type: "n-input",
            value: "",
          },
          containerId: {
            label: "Container ID",
            type: "n-input",
            value: "",
          },
          containerName: {
            label: "Container Name",
            type: "n-input",
            value: "",
          }
        },
        ga4: {
          accountId: {
            label: "Account ID",
            type: "n-input",
            value: ""
          },
          propertyId: {
            label: "Property ID",
            type: "n-input",
            value: ""
          },
          displayName: {
            label: "Display Name",
            type: "n-input",
            value: ""
          }
        },
        sa360: {
          loginCustomerId: {
            label: "Login Customer ID",
            type: "n-input",
            value: ""
          },
          customerId: {
            label: "Customer ID",
            type: "n-input",
            value: ""
          },
          customerName: {
            label: "Customer Name",
            type: "n-input",
            value: ""
          }
        },
        dv360: {
          partnerId: {
            label: "Partner ID",
            type: "n-input",
            value: ""
          },
          advertiserId: {
            label: "Advertiser ID",
            type: "n-input",
            value: ""
          },
          advertiserName: {
            label: "Advertiser Name",
            type: "n-input",
            value: ""
          }
        },
        cm360: {
          accountId: {
            label: "Account ID",
            type: "n-input",
            value: ""
          },
          advertiserId: {
            label: "Advertiser ID",
            type: "n-input",
            value: ""
          },
          advertiserName: {
            label: "Advertiser Name",
            type: "n-input",
            value: ""
          }
        },
        googleAds: {
          loginCustomerId: {
            label: "Login Customer ID",
            type: "n-input",
            value: ""
          },
          customerId: {
            label: "Customer ID",
            type: "n-input",
            value: ""
          },
          customerName: {
            label: "Customer Name",
            type: "n-input",
            value: ""
          }
        },
        meta: {
          adAccountId: {
            label: 'Ad Account ID',
            type: 'n-input',
            value: ''
          }
        }
      }
    };
  },
  methods: {
    getCredentialIdOptions() {
      CredentialService.call('listAvailableCredentials', { orgid: 'root' }).then(response => {
        this.credentialIdOptions = response.result.credentials
          .filter((credential) => {
            if (credential.broken) return false;
            if (credential.type !== 'oauthuser' && credential.type !== 'serviceacct') return false;
            for (let credentialTag of credential.tags || []) {
              if (credentialTag === 'RootClientId' || credentialTag === 'SharedAgentCredential' || credentialTag === 'GlobalShared') return false;
            }
            return true;
          })
          .map((credential) => {
            return {
              label: credential.name,
              value: credential.credentialid
            };
          });
      });
    },
    add() {
      let extServices = null;
      extServices = this.generateServiceConfig();
      MetadataService.call('analyticscontainers.create', {
        data: {
          "orgid": this.$store.state.organization,
          "name": this.name,
          "containertype": "site",
          "extservices": extServices
        }
      }).then(response => {
        if (response.error) {
          this.triggerError(response.error);
          return;
        }
        this.closeClick();
      });
    },
    update() {
      let extServices = null;
      extServices = this.generateServiceConfig();

      MetadataService.call('analyticscontainers.update', {
        containerid: this.editContainer.container.containerid,
        updates: {
          name: this.name,
          extservices: extServices
        }
      }).then(response => {
        if (response.error) {
          this.triggerError(response.error);
          return;
        }
        this.closeClick();
      });
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = "Oops. Something went wrong.";
      this.loading = false;
    },
    initializeFormIfEdit() {
      if (this.editContainer.container != null) {
        this.name = this.editContainer.container.name;
        this.type = this.editContainer.container.type;

        const extServices = this.editContainer.container.extservices;
        if (extServices && this.type in extServices) {
          const serviceData = extServices[this.type];
          console.log(serviceData);

          if (this.serviceFields[this.type]) {
            Object.keys(this.serviceFields[this.type]).forEach((fieldKey) => {
              if (serviceData[fieldKey] !== undefined) {
                this.serviceFields[this.type][fieldKey].value = serviceData[fieldKey];
              }
            });

            this.credentials = serviceData.credentialspecs?.default?.credentialids?.[0] || "";
          }
        }
      }
    },
    generateServiceConfig() {
      if (!this.type || !this.serviceFields[this.type]) {
        console.error("Invalid or undefined service type.");
        return null;
      }

      const serviceConfig = {
        [this.type]: Object.keys(this.serviceFields[this.type]).reduce(
          (acc, key) => {
            acc[key] = this.serviceFields[this.type][key].value;
            return acc;
          },
          {}
        )
      };

      serviceConfig[this.type].credentialspecs = {
        default: {
          credentialids: [this.credentials]
        }
      };

      return serviceConfig;
    }
  },
  mounted() {
    this.getCredentialIdOptions();
    this.initializeFormIfEdit();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
</style>
