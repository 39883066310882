<template>
  <div class="view-container dashboard">
    <section class="view-header">
      <div class="view-title">Dashboard</div>
    </section>

    <div class="widget full" v-if="error || loading">
      <div class="site-error" v-if="error || !loading">{{ error }}</div>
      <div class="site-loading" v-if="!error && loading">
        <LoadingSpinner /> Loading...
      </div>
    </div>
    <div class="workarea" v-if="!loading && !error">
      <ComplianceScoreWidget
        :scans="$store.state.scans"
        v-if="NODE_ENV !== 'demo'"
      />
      <ComplianceScoreWithMisconfiguredSpendWidget
        :scans="$store.state.scans"
        v-if="NODE_ENV === 'demo'"
      />
      <!-- <RecentInsightsWidget /> -->
      <LeaderboardWidget
        title="Governance & Standards"
        buttonText="Learn More"
        buttonLink="/platform-governance/overview"
        :scans="$store.state.scans"
        :size="`${NODE_ENV !== 'demo' ? 'half' : 'third'}`"
        v-if="NODE_ENV !== 'demo' && featurePermissions.includes('platform-governance')"
      />
      <TagConsentMonitoringWidget
        v-if="featurePermissions.includes('tag-consent-monitoring')"
      />
      <MediaPerformanceWidget
        v-if="featurePermissions.includes('media-performance')"
      />
    </div>
  </div>
</template>

<script>
// import RecentInsightsWidget from "@/components/Dashboard/RecentInsightsWidget";
import ComplianceScoreWidget from "@/components/Dashboard/ComplianceScoreWidget";
import ComplianceScoreWithMisconfiguredSpendWidget from "@/components/Dashboard/ComplianceScoreWithMisconfiguredSpendWidget";
import TagConsentMonitoringWidget from "@/components/Dashboard/TagConsentMonitoringWidget";
import MediaPerformanceWidget from "@/components/Dashboard/MediaPerformanceWidget";
import LeaderboardWidget from "@/components/Shared/LeaderboardWidget";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { getNoOrgErrorMessage } from "@/ErrorMessaging";

export default {
  name: "DashboardView",
  components: {
    // RecentInsightsWidget,
    ComplianceScoreWithMisconfiguredSpendWidget,
    ComplianceScoreWidget,
    TagConsentMonitoringWidget,
    MediaPerformanceWidget,
    LeaderboardWidget,
    LoadingSpinner,
  },
  data() {
    return {
      loading: true,
      error: null,
      authCredentials: [],
      NODE_ENV: process.env.NODE_ENV,
    };
  },
  methods: {
    handleLoadingAndError(scans, containers) {
      if (
        (!this.$store.state.organizationOptions ||
          this.$store.state.organizationOptions.length === 0) &&
        this.$store.state.organization
      ) {
        this.error = getNoOrgErrorMessage();
        this.loading = false;
      } else if (scans && containers) {
        this.loading = false;
      }
    },
  },
  computed: {
    featurePermissions() {
      return this.$store.getters.frontendFlags.enabled_features || [];
    }
  },
  mounted() {
    const scans = this.$store.state.scans;
    const containers = this.$store.state.containers;
    if (!scans) {
      this.$store.dispatch("getScans");
    }

    if (!containers) {
      this.$store.dispatch("getContainers");
    }

    if (containers && scans && this.$store.state.organization) {
      this.handleLoadingAndError(scans, containers);
    }
  },
  watch: {
    "$store.state.containers": function (newContainers) {
      const newOrg = this.$store.state.organization;
      const newScans = this.$store.state.scans;
      if (newContainers && newScans && newOrg) {
        this.handleLoadingAndError(newScans, newContainers);
      }
    },
    "$store.state.scans": function (newScans) {
      const newOrg = this.$store.state.organization;
      const newContainers = this.$store.state.containers;
      if (newContainers && newScans && newOrg) {
        this.handleLoadingAndError(newScans, newContainers);
      }
    },
    "this.$store.state.organization": function (newOrg) {
      const newScans = this.$store.state.scans;
      const newContainers = this.$store.state.containers;
      if (newContainers && newScans && newOrg) {
        this.handleLoadingAndError(newScans, newContainers);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
</style>
