<template>
  <section class="top-bar" v-if="$store.state.userData != null">
    <div>
      <router-link to="/" class="app-title">
        <DisplaySvg name="logo-full" class="logo" />
      </router-link>
      <div class="right-options">
        <router-link to="/manage-account/profile" class="manage">
          Settings <DisplaySvg name="gear" class="gear" />
        </router-link>
        <div
        class="updateOrgForm"
        v-if="
          $store.state.organizationOptions &&
          $store.state.organizationOptions.length > 0
        "
        >
          <UpdateOrgForm />
        </div>
      </div>
      <a
      href="javascript:void(0);"
      v-bind:class="getHamburgerMenuClasses()"
      @click="toggleMobileMenu"
      >
        <div></div>
      </a>
    </div>
  </section>
  <section class="app-body">
    <section
      v-bind:class="getSidebarClasses()"
      v-if="$store.state.userData != null"
    >
      <div>
        <div class="sidebar-inner">
          <div class="account">
            <div class="user-circle">
              {{ getUserInitials() }}
            </div>
            <div class="user-info">
              <label>{{ $store.state.userData.user.name }}</label>
              <a class="logout" @click="$store.commit('logout')">Logout</a>
            </div>
          </div>

          <nav>
            <div
              v-for="appLink in appLinks"
              :key="appLink.name"
              :class="{
                current: isActiveRoute(appLink.relativePath),
              }"
            >
              <router-link
                v-if="!isRouteDisabled(appLink.name)"
                :to="appLink.relativePath"
              >
                {{ appLink.name }}
              </router-link>

              <span class="isDisabled" v-if="isRouteDisabled(appLink.name)">
                {{ appLink.name }}
              </span>
              <NTooltip
                placement="bottom"
                trigger="hover"
                v-if="isRouteDisabled(appLink.name)"
              >
                <template #trigger>
                  <DisplaySvg name="info" class="disabled-info" />
                </template>
                <div class="disabled-info-text">
                  Speak with your InfoTrust <br />
                  advisor to enable this feature
                </div>
              </NTooltip>
            </div>
          </nav>
          <div class="divider"></div>
          <div class="links">
            <a href="/changelog" v-if="releases && !releases[0].error">
              What's New
              <span
                v-if="
                  $store.state.whatsNewCount && $store.state.whatsNewCount > 0
                "
                class="whatsNewCount"
                >{{ $store.state.whatsNewCount }}</span
              >
            </a>
            <a href="/manage-account/profile" class="hideOnDesktop">
              Manage Account
            </a>
          </div>
          <div
            class="divider hideOnDesktop"
            v-if="
              $store.state.organizationOptions &&
              $store.state.organizationOptions.length > 0
            "
          ></div>
          <div
            class="updateOrgForm_mobileSidebar"
            v-if="
              $store.state.organizationOptions &&
              $store.state.organizationOptions.length > 0
            "
          >
            <UpdateOrgForm />
          </div>
          <a href="https://infotrust.com/" target="_blank">
            <DisplaySvg name="infotrust-logo" class="infotrust-logo" />
          </a>
        </div>
      </div>
    </section>
    <!-- children -->
    <slot />
  </section>
</template>

<script>
import UpdateOrgForm from "@/components/ManageAccount/UpdateOrgForm.vue";
import { NTooltip } from "naive-ui";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import { ref } from "vue";

const isMobileMenuOpen = ref(false);

export default {
  name: "SiteHeaderAndSidebar",
  components: {
    NTooltip,
    DisplaySvg,
    UpdateOrgForm,
  },
  watch: {
    "$store.state.githubReleaseData": function () {
      this.$nextTick(async () => {
        if (
          this.$store.state.githubReleaseData &&
          this.$store.state.githubReleaseData.length > 0
        ) {
          this.releases = this.$store.state.githubReleaseData;
        }
      });
    },
    "$store.state.frontendFlags": function () {
      this.$nextTick(async () => {
        this.featurePermissions = this.$store.state.frontendFlags || [];
      });
    },
  },
  computed: {
    featurePermissions() {
      return this.$store.getters.frontendFlags.enabled_features || [];
    }
  },
  methods: {
    isRouteDisabled(routeName) {
      if (
        routeName === "Tag & Consent Monitoring" &&
        (this.featurePermissions.indexOf('tag-consent-monitoring') === -1)
      ) {
        return true;
      }

      if (
        routeName === "Platform Governance & Standards" &&
        (this.featurePermissions.indexOf('platform-governance') === -1)
      ) {
        return true;
      }

      if (
        routeName === "Media Performance" &&
        (this.featurePermissions.indexOf('media-performance') === -1)
      ) {
        return true;
      }

      return false;
    },
    updateOrg(newValue) {
      this.$store.commit("setOrganization", newValue);
    },
    getUserInitials() {
      if (this.$store.state.userData == null) return;
      const parts = this.$store.state.userData.user.name.split(" ");
      if (parts.length === 1) {
        return parts[0].charAt(0);
      } else if (parts.length > 1) {
        return parts[0].charAt(0) + parts[parts.length - 1].charAt(0);
      }
      return null;
    },
    isActiveRoute(routePath) {
      return this.$route.path.split("/")[1] === routePath.split("/")[1];
    },
    toggleMobileMenu() {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
    },
    getHamburgerMenuClasses() {
      return {
        hamburger: true,
        open: isMobileMenuOpen.value,
      };
    },
    getSidebarClasses() {
      return {
        sidebar: true,
        open: isMobileMenuOpen.value,
      };
    },
  },
  data() {
    const mainNavLinks = [
      {
        name: "Dashboard",
        relativePath: "/dashboard",
      },
      {
        name: "Tag & Consent Monitoring",
        enabledFeaturesFlagName: 'tag-consent-monitoring',
        relativePath: "/tag-consent/overview",
      },
      {
        name: "Platform Governance & Standards",
        enabledFeaturesFlagName: 'platform-governance',
        relativePath: "/platform-governance/overview",
      },
      {
        name: "Media Performance",
        enabledFeaturesFlagName: 'media-performance',
        relativePath: "/media-performance/overview",
      },
    ];

    return {
      NODE_ENV: process.env.NODE_ENV,
      appLinks: mainNavLinks,
      page: null,
      releases: null
    };
  },
  updated() {
    this.page = this.$route.name;
  },
  mounted() {
    if (
      this.$store.state.githubReleaseData &&
      this.$store.state.githubReleaseData.length > 0
    ) {
      this.releases = this.$store.state.githubReleaseData;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
.top-bar {
  height: 80px;

  > div {
    width: 100vw;
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 2rem;
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: $shadow;
    background: $white;
    z-index: 99999;
  }
  .right-options {
    display: flex;
    align-items: center;
    .updateOrgForm {
      margin: -20px 0 0 30px;
      width: 200px;
    }
  }

  .logo {
    width: 210px;
  }

  .app-title {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: $navy;
    font-size: 24px;
    font-weight: 600;
    text-decoration: none;
  }

  .manage {
    color: $navy;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    transition: $transition color;
    cursor: pointer;
    .gear {
      width: 16px;
      height: 16px;
      display: inline-block;
      position: relative;
      top: 2px;
      margin-left: 5px;
    }

    @include hover {
      color: $blue;
    }
  }

  .hamburger {
    display: none;
    visibility: hidden;
    width: 20px;
    height: 18px;
    position: relative;

    &:before,
    &:after,
    > div {
      height: 4px;
      width: 100%;
      background-color: $navy;
      position: absolute;
      border-radius: 3px;
    }

    &:before {
      content: "";
      top: 0px;
      transition: $transition transform, $transition top;
    }

    &:after {
      content: "";
      top: 14px;
      transition: $transition transform, $transition top;
    }

    > div {
      top: 7px;
      opacity: 1;
      transition: $transition opacity;
    }

    &.open {
      &:before {
        top: 7px;
        transform: rotate(45deg);
      }

      > div {
        opacity: 0;
      }

      &:after {
        top: 7px;
        transform: rotate(-45deg);
      }
    }
  }
}

.app-body {
  display: flex;
  min-height: calc(100vh - 80px);
  position: relative;

  .sidebar {
    background: $navy;
    width: 320px;
    flex-shrink: 0;

    > div {
      position: fixed;
      top: 0;
      left: 0;
      width: 320px;
      height: calc(100vh);
      overflow: auto;
      background: $navy;
      padding-top: 80px;
    }

    .infotrust-logo {
      width: 80px;
      height: 24px;
      color: $white;
      margin-left: 16px;
      opacity: 0.9;
      transition: $transition opacity;

      @include hover {
        opacity: 1;
      }

      @include media($small, height) {
        position: absolute;
        bottom: 30px;
        left: 50px;
        margin-left: 0;
      }
    }

    .sidebar-inner {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      flex: 0 0 260px;
      padding: 2rem;
    }

    .account {
      display: flex;
      align-items: center;
      padding: 1rem;
      background: rgba($white, 0.1);
      border-radius: 10px;
      gap: 1rem;

      .user-circle {
        flex: 0 0 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background: $white;
        border-radius: 50%;
        color: $blue;
        font-size: 24px;
        font-weight: 600;
      }

      .user-info {
        display: flex;
        flex-direction: column;
        flex: 0 1 auto;

        label {
          font-weight: 600;
          color: $white;
          margin-bottom: 0.25rem;
        }

        .logout {
          text-transform: uppercase;
          color: $white;
          font-size: 0.85rem;
          opacity: 0.85;
          transition: $transition opacity;
          width: 60px;
          cursor: pointer;

          @include hover {
            opacity: 1;
          }
        }
      }
    }

    nav {
      display: flex;
      flex-direction: column;
      gap: 0rem;
      > div {
        padding: 1rem;
        border-radius: 10px;

        &.current {
          background: rgba($white, 0.15);
          a {
            opacity: 1;
          }
        }
      }

      a {
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.9rem;
        opacity: 0.85;
        transition: $transition opacity;

        @include hover {
          opacity: 1;
        }
      }

      .isDisabled {
        opacity: 0.5;
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.9rem;
      }

      .disabled-info {
        color: $white;
        opacity: 0.5;
        width: 16px;
        height: 16px;
        display: inline-block;
        position: relative;
        top: 2px;
        margin-left: 6px;
      }
    }

    .divider {
      width: 120px;
      height: 1px;
      background: rgba($white, 0.5);
      margin-left: 1rem;
    }

    .links {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-left: 1rem;

      .whatsNewCount {
        background-color: $green;
        color: $white;
        border-radius: 20px;
        display: inline-block;
        height: 20px;
        width: 20px;
        text-align: center;
        position: relative;
        left: 4px;
        top: 0px;
        font-size: 13px;
        padding: 1px;
      }

      a {
        color: $white;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 0.85rem;
        cursor: pointer;
        opacity: 0.85;
        transition: $transition opacity;

        @include hover {
          opacity: 1;
        }
      }
    }
  }
}

.hideOnDesktop {
  display: none;
  visibility: hidden;
}

@include media($small-highest, down) {
  .app-body {
    .sidebar {
      width: 100%;
      top: 80px;
      height: 0;
      overflow: hidden;
      position: fixed;
      z-index: 99999;
      min-height: none;
      transition: $transition height;
      > div {
        width: 100%;
        position: relative;
        height: calc(100vh - 80px);
        padding: 0;
      }

      .infotrust-logo {
        margin-left: 16px !important;
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
      }

      .sidebar-inner {
        padding: 2rem 1rem;
      }

      &.open {
        height: calc(100vh - 80px);
      }
    }
  }

  .top-bar {
    .manage {
      display: none;
      visibility: hidden;
    }

    .updateOrgForm {
      display: none;
      visibility: hidden;
    }

    .hamburger {
      display: block;
      visibility: visible;
    }
  }

  .hideOnDesktop {
    display: block;
    visibility: visible;
  }
}
</style>
