<template>
  <div class="widget violation-filtered" :class="size">
    <div class="title-with-btn">
      <h2 class="title">Violation Count Filtered</h2>
      <div class="filter-select">
        <span>Violation Count by</span>
        <NSelect
          v-model:value="selectedDimension"
          :options="dimensionOptions"
          class="platform-select"
        />
      </div>
    </div>
    <div class="violations-list">
      <div v-if="localLoading" class="site-loading">
        <LoadingSpinner /> Loading...
      </div>
      <template v-else>
        <div v-for="(item, index) in sortedViolationItems" :key="item.name" class="violation-item">
          <div class="violation-info">
            <span class="name">{{ item.name }}</span>
            <div class="bar-and-count">
              <div class="violation-bar">
                <div 
                  class="bar-fill"
                  :style="{ width: `${getPercentage(item.count)}%` }"
                  :class="getColorClass(item.count)"
                ></div>
              </div>
              <span class="count">{{ item.count }}</span>
            </div>
          </div>
          <div v-if="index < sortedViolationItems.length - 1" class="separator"></div>
        </div>
      </template>
    </div>
    <!--
    <router-link
      :to="`/platform-governance/violations?org=${encodeURIComponent(
        this.$store.state.organization
      )}`"
      class="button"
    >VIEW VIOLATIONS</router-link>
    -->
  </div>
</template>

<script>
import { NSelect } from 'naive-ui';
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import AdtechService from "@/services/AdtechService";

export default {
  name: "ViolationCountFiltered",
  components: {
    NSelect,
    LoadingSpinner
  },
  props: {
    scans: {
      type: Array,
      required: true
    },
    size: {
      type: String,
      default: "half"
    }
  },
  data() {
    if (process.env.NODE_ENV === 'demo') {
      return {
        isDemoEnv: true,
        localLoading: false,
        selectedDimension: 'PLATFORM',
        violationData: []
      };
    } else {
      return {
        isDemoEnv: false,
        localLoading: false,
        selectedDimension: null,
        violationData: []
      };
    }
  },
  computed: {
    currentScanId() {
      return this.$store.state.scans && this.$store.state.scans[0] && this.$store.state.scans[0].scanid;
    },
    filterParams() {
      return this.$store.getters.currentFilterParams;
    },
    dimensionOptions() {
      if (this.isDemoEnv) {
        return [
          {
            label: 'Platform',
            value: 'PLATFORM'
          },
          {
            label: 'Category',
            value: 'CATEGORY'
          }
        ];
      } else {
        let filterOptions = this.$store.state.platformGovernanceFilterOptions;
        return (filterOptions || []).map((elem) => {
          return {
            label: elem.dimension,
            value: elem.dimension.toUpperCase()
          };
        });
      }
    },
    sortedViolationItems() {
      if (this.isDemoEnv) {
        return [
          { name: 'GA4', count: 438, color: 'red' },
          { name: 'SA360', count: 302, color: 'red' },
          { name: 'XYZ3', count: 256, color: 'red' },
          { name: 'XYZ2', count: 110, color: 'yellow' },
          { name: 'DV360', count: 97, color: 'yellow' }
        ]
      }

      if (!this.violationData.length) return [];
      return [ ...this.violationData ]
        .sort((a, b) => b.count - a.count)
        .slice(0, 5);
    },
    maxCount() {
      if (!this.sortedViolationItems.length) return 1;
      return Math.max(...this.sortedViolationItems.map(item => item.count), 1);
    }
  },
  methods: {
    async fetchViolationData() {
      if (this.isDemoEnv) return;
      if (!this.currentScanId || !this.dimensionOptions || !this.selectedDimension) return;
      
      this.localLoading = true;
      try {
        let selectedDimensionObj = this.dimensionOptions.find((elem) => elem.value === this.selectedDimension);
        if (!selectedDimensionObj) throw new Error('Unknown selectedDimension');
        const response = await await AdtechService.call(
					"reports.custom.scanRuleRunsAggregate",
					{
						scanid: this.currentScanId,
            filter: this.filterParams || {},
            groupBy: [ `entity.dimensions.${selectedDimensionObj.label}` ]
					}
				);
        const responseGroups = response?.result?.['custom.scanRuleRunsAggregate'];
        if (responseGroups) {
          this.violationData = responseGroups.map((groupObj) => {
            const groupKey = `entity_dimension_${this.selectedDimension.toLowerCase()}`;
            return {
              name: groupObj.group?.[groupKey] || 'Unknown',
              count: groupObj.violation_count || 0
            };
          });
        }
        return [];
      } catch (error) {
        console.error('Error fetching violation data:', error);
        this.violationData = [];
      } finally {
        this.localLoading = false;
      }
    },
    getPercentage(count) {
      const total = this.sortedViolationItems.reduce((sum, item) => sum + item.count, 0);
      return (count / total) * 100;
    },

    getColorClass(count) {
      const percentage = this.getPercentage(count);
      
      if (percentage > 60) return 'red';
      if (percentage > 20) return 'orange';
      return 'yellow';
    }
  },
  watch: {
    currentScanId() {
      this.fetchViolationData();
    },
    filterParams() {
      this.fetchViolationData();
    },
    dimensionOptions() {
      this.selectedDimension = 'PLATFORM';
    },
    selectedDimension() {
      if (!this.isDemoEnv) {
        this.fetchViolationData();
      }
    }
  },
  mounted() {
    this.selectedDimension = 'PLATFORM';
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.violation-filtered {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .title-with-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    width: 100%;

    .title {
      font-size: 1.25rem;
      color: $navy;
      margin: 0;
    }

    .filter-select {
      display: flex;
      gap: 1rem;
      align-items: center;

      span {
        color: $navy;
        font-size: 14px;
      }

      .platform-select {
        width: 150px;
      }
    }
  }

  .violations-list {
    margin-bottom: 2rem;
    width: 100%;
  }

  .violation-item {
    padding: 0.75rem 0;

    .violation-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .name {
        color: $navy;
        font-weight: 500;
        min-width: 80px;
      }

      .bar-and-count {
        display: flex;
        align-items: center;
        flex: 1;
        margin-left: 1rem;
        gap: 1rem;
      }

      .count {
        color: #666;
        min-width: 40px;
        text-align: right;
      }
    }

    .violation-bar {
      flex: 1;
      height: 8px;
      background-color: #f0f0f0;
      border-radius: 4px;
      overflow: hidden;

      .bar-fill {
        height: 100%;
        border-radius: 4px;
        transition: width 0.3s ease;

        &.red {
          background-color: $red;
        }

        &.orange {
          background-color: $orange;
        }

        &.yellow {
          background-color: $yellow;
        }
      }
    }
  }

  .separator {
    margin-top: 0.75rem;
    border-bottom: 1px solid $navy-faded;
  }

  .button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: white;
    border: 1px solid $navy;
    color: $navy;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.875rem;
    
    &:hover {
      background-color: $navy;
      color: white;
    }
  }
}
</style>